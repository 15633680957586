<script setup lang="ts">
    const props = defineProps({
  submenuPosition: { default: 'right-0' },
  withTemplateVariables: { type: Boolean, default: false },
  iconColor: { default: 'text-neutral-800' }
});
    const input: Ref<HTMLElement | null> = ref(null);

    const emit = defineEmits(['add-variable']);
    const query: Ref<string> = ref('');

    const searchedVariables = computed(() => {
        return variables
            .map((group) => ({
                key: group.key,
                variables: group.variables.filter((variable) => variable.toLowerCase().includes(query.value.toLowerCase())),
            }))
            .filter((group) => group.variables.length > 0);
    });

    const variables = [
        {
            key: 'company',
            variables: [
                'company_name',
                'company_identification_number',
                'company_vat_number',
                'company_siret',
                'company_email',
                'company_phone',
                'company_address_street_name',
                'company_address_street_number',
                'company_address_street_box_number',
                'company_address_postal_code',
                'company_address_city',
                'company_iban',
            ],
        },
        {
            key: 'document',
            variables: ['document_title', 'document_type', 'document_number', 'document_date', 'document_dueat', 'document_totalremaining', 'document_total', 'document_communication'],
        },
        {
            key: 'client',
            variables: [
                'client_company_or_name',
                'client_name',
                'client_first_name',
                'client_last_name',
                'client_honorific_prefix',
                'client_company_name',
                'client_identification_number',
                'client_vat_number',
                'client_siret',
                'client_address_street_name',
                'client_address_street_number',
                'client_address_street_box_number',
                'client_address_postal_code',
                'client_address_city',
                'client_address_country',
                'client_billingaddress_street_name',
                'client_billingaddress_street_number',
                'client_billingaddress_street_box_number',
                'client_billingaddress_postal_code',
                'client_billingaddress_city',
                'client_billingaddress_country',
            ],
        },
    ];
    if (props.withTemplateVariables) {
        variables.unshift({
            key: 'template',
            variables: ['company_logo', 'document_title', 'document_description', 'document_conditions'],
        });
    }

    const resetQuery = () => {
        query.value = '';
    };
</script>

<template>
    <Submenu
        relative-div-class="h-[24px]"
        :position="submenuPosition"
    >
        <template #default="{ toggle }">
            <slot :toggle="toggle">
                <button
                    id="variables-button"
                    data-tooltip="Variables"
                    @click.prevent="
                        toggle();
                        resetQuery();
                    "
                >
                    <i
                        class="fa-regular fa-percentage block -mt-[2px] text-sm"
                        :class="iconColor"
                    />
                </button>
            </slot>
        </template>
        <template #submenu="{ hide }">
            <div :class="$theme('submenu.items_container')">
                <p class="mb-2">
                    {{ $t('selects.variables.title') }}
                </p>
                <div class="relative mb-2">
                    <input
                        ref="input"
                        v-model="query"
                        type="search"
                        autocomplete="off"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('selects.variables.search_input_placeholder')"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"></i>
                    </div>
                </div>
                <div
                    v-for="group in searchedVariables"
                    :key="group.key"
                    :class="$theme('submenu.items')"
                >
                    <p class="pl-4 my-1 text-sm text-neutral-600">
                        {{ $t(`variables.group.${group.key}`) }}
                    </p>
                    <div
                        v-for="variable in group.variables"
                        :key="variable"
                        class="px-4 py-2 text-[13px] text-neutral-700 transition-all rounded cursor-pointer hover:text-primary hover:bg-neutral-100 active:bg-neutral-200"
                        @click.prevent="
                            emit('add-variable', variable);
                            resetQuery();
                            hide();
                        "
                    >
                        <div class="">%{{ variable }}%</div>
                        <span class="block text-[10px] -mt-[2px]">
                            {{ $t(`variables.${variable}`) }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
