<script setup lang="ts">
    import { FormInputData } from '~~/composables/useFormInput';

    const availableLocales = useAvailableLocalesByCountry();
    const emit = defineEmits(['label-clicked']);
    const props = defineProps({
  inputData: null
});

    const addVariable = (variable: string) => {
        props.inputData.model.value = `${props.inputData.model.value} %${variable}%`;
    };
</script>

<template>
    <div :class="inputData.elementClassName.value">
        <div :class="[inputData.containerClassName.value, inputData.inlineLabel ? 'flex flex-row-reverse items-center space-x-2 space-x-reverse' : '']">
            <div
                v-if="!inputData.withoutLabel && inputData.label.value"
                :class="$theme('form.containers.label')"
            >
                <label
                    :class="inputData.inlineLabel ? $theme('form.label_inline') : $theme('form.label')"
                    :for="inputData.inputId.value"
                    @click="emit('label-clicked')"
                >
                    {{ inputData.label.value }}
                    <span
                        v-if="inputData.tooltip"
                        :data-tooltip="inputData.tooltip"
                    >
                        <i class="text-xs fa-regular fa-circle-info" />
                    </span>
                    <Submenu
                        v-if="inputData.translated"
                        relative-div-class="inline-block mb-2 ml-2"
                    >
                        <template #default="{ toggle }">
                            <span
                                class="px-2 py-1 text-neutral-600 bg-white transition-all border border-gray-200 rounded text-[11px] hover:bg-neutral-100 cursor-pointer"
                                @click.prevent="toggle"
                            >
                                <i class="mr-1 text-xs fa-regular fa-flag" />
                                <span class="inline-block uppercase">
                                    {{ inputData.locale.value }}
                                </span>
                            </span>
                        </template>
                        <template #submenu="{ hide }">
                            <div class="w-full min-w-[260px]">
                                <div
                                    v-for="(locales, country) in availableLocales"
                                    :key="`language-switcher-country-${country}`"
                                    :class="$theme('submenu.items')"
                                >
                                    <a
                                        v-for="availableLocale in locales"
                                        :key="availableLocale.locale"
                                        href="#"
                                        :class="$theme('submenu.item_without_icon', 'flex items-center justify-between')"
                                        @click.prevent="
                                            inputData.locale.value = availableLocale.locale;
                                            hide();
                                        "
                                    >
                                        <div class="flex items-center space-x-2">
                                            <img
                                                class="h-[16px] bg-neutral-100 min-w-[18px]"
                                                :src="`https://trustup-io-shared-assets.ams3.cdn.digitaloceanspaces.com/images/translations/flags/${country}.svg`"
                                                alt=""
                                            />
                                            <span>
                                                {{ $t(`locales.native.${availableLocale.language}`) }}
                                            </span>
                                        </div>
                                        <span :data-tooltip="!inputData.hasTranslation(availableLocale.locale) ? $t('misc.missing_translation') : null">
                                            <i
                                                v-if="!inputData.hasTranslation(availableLocale.locale)"
                                                class="text-sm fa-regular fa-empty-set"
                                                :class="$theme('submenu.item_icon')"
                                            />
                                            <i
                                                v-if="availableLocale.locale === inputData.locale.value && inputData.hasTranslation(availableLocale.locale)"
                                                class="text-sm fa-regular fa-circle-check"
                                                :class="$theme('submenu.item_icon')"
                                            />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </template>
                    </Submenu>
                    <FormPartialsVariablesSubmenu
                        v-if="inputData.withVariables"
                        relative-div-class="inline-block mb-2 ml-2"
                        submenu-position="left-0"
                        @add-variable="addVariable"
                    >
                        <template #default="{ toggle }">
                            <span
                                class="px-2 py-1 text-neutral-600 bg-white transition-all border border-gray-200 rounded text-[11px] hover:bg-neutral-100 cursor-pointer"
                                @click.prevent="toggle"
                            >
                                <i class="text-xs fa-regular fa-percentage" />
                            </span>
                        </template>
                    </FormPartialsVariablesSubmenu>
                </label>
            </div>
            <slot></slot>
            <span
                v-if="inputData.form.errors?.has(inputData.inputName)"
                class="block text-xs text-red-600 mt-[4px]"
            >
                {{ inputData.form.errors.get(inputData.inputName) }}
            </span>
        </div>
    </div>
</template>
